import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined'
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined'
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBagOutlined'
import StarsIcon from '@mui/icons-material/Stars'
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import {
  DeliveryMode,
  FulfillmentStatus,
  Order,
  OrderOrigin,
  ShippingMethod,
  TaskState,
} from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import { ReactNode } from 'react'
import { formatNumberRange } from '../../../utils/formatNumberRange'
import { DeliveryProviderBadge } from '../../DeliveryProviderBadge'
import OrderNumber from '../../OrderNumber'
import { EtaTimestamp } from '../../OrdersList/EtaTimestamp'
import { StatusDuration } from '../../OrdersList/StatusDuration'
import { Rating } from '../../Rating/Rating'
import { ShortWarehouseId } from '../../ShortWarehouseId'
import { OrderTableColumnId } from '../types/OrderTableColumnId'

const MINUTE_IN_MS = 60000

export const renderOrderTableCellByColumnId = (columnId: OrderTableColumnId, order: Order): ReactNode => {
  switch (columnId) {
    case OrderTableColumnId.DELIVERY_MODE:
      return order.deliveryMode === DeliveryMode.PRIORITY ? <StarsIcon /> : <Box width="8px" />
    case OrderTableColumnId.ID:
      return (
        <Tooltip title={order.warehouseId}>
          <div>
            {order.isNewCustomer && (
              <Stack
                component="span"
                sx={{
                  display: 'inline',
                  marginRight: '12px',
                  padding: '2px 4px',
                  backgroundColor: 'primary.main',
                  borderRadius: '4px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                }}
              >
                new
              </Stack>
            )}
            {order.externalId || <OrderNumber value={order.number} />}
            <br />
            <ShortWarehouseId warehouseId={order.warehouseId} />
          </div>
        </Tooltip>
      )

    case OrderTableColumnId.CREATED_AT:
      const date = order.createdAt && DateTime.fromMillis(order.createdAt)
      return (
        <Tooltip title={date ? date?.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS) : '-'}>
          <div>{date ? date.toFormat('HH:mm') : '—'}</div>
        </Tooltip>
      )

    case OrderTableColumnId.PACKAGE_COUNT:
      return order.packageCount ? (
        <Stack direction="row" alignItems="center">
          <ShoppingBagIcon />
          <Typography pt={0.5}>{order.packageCount}</Typography>
        </Stack>
      ) : (
        '—'
      )

    case OrderTableColumnId.FULFILLMENT_STATUS:
      return order.fulfillmentStatus || '—'

    case OrderTableColumnId.DELIVERY_STATUS:
      const deliveryStatus = !!order.deliveryStatus
      const isDelivered = order.deliveryStatus === TaskState.COMPLETED
      const rating = order.customerRating?.rating

      if (!deliveryStatus) return order.shippingMethod || '—'
      if (isDelivered && rating) return <Rating rating={rating} note={order.customerRating?.note} size="small" />
      return order.deliveryStatus

    case OrderTableColumnId.PICKING_STATUS:
      return order.pickingStatus || '—'

    case OrderTableColumnId.PICKER:
      return order.picker?.name || '—'

    case OrderTableColumnId.RIDER:
      const riderName = order.rider?.name
      const isStacked = !!order.stackedWithTaskIds?.length
      const isShowEta =
        order.fulfillmentStatus === FulfillmentStatus.UNFULFILLED &&
        riderName &&
        !riderName?.includes('Waiting for') &&
        ![TaskState.FAILED, TaskState.COMPLETED, TaskState.RETURNED, TaskState.NOT_RETURNED].includes(
          order.deliveryStatus
        )

      return (
        <>
          {isStacked && (
            <Tooltip title="Stacked task">
              <LayersOutlinedIcon
                color="primary"
                sx={{
                  padding: '0px',
                  fontSize: '21px',
                  marginBottom: '-5px',
                  marginRight: '3px',
                }}
              />
            </Tooltip>
          )}
          {riderName || '-'}
          {isShowEta && (
            <EtaTimestamp
              order={order}
              orderDeliveryStatus={order.deliveryStatus}
              orderDeliveryStartedAt={order.deliveryStartedAt}
              orderEstimatedDeliveryAt={order.estimatedDeliveryAt}
              orderEstimatedArrivalAtStoreAt={order.estimatedArrivalAtStoreAt}
              isStacked={!!order.stackedWithTaskIds?.length}
            />
          )}
        </>
      )

    case OrderTableColumnId.RIDER_V2:
      const isPickUp = order.shippingMethod === ShippingMethod.PICKUP
      const riderNameV2 = isPickUp ? 'PICKUP' : order.rider?.name
      const isStackedV2 = !!order.stackedWithTaskIds?.length
      const isShowEtaV2 =
        order.fulfillmentStatus === FulfillmentStatus.UNFULFILLED &&
        riderNameV2 &&
        !riderNameV2?.includes('Waiting for') &&
        ![TaskState.FAILED, TaskState.COMPLETED, TaskState.RETURNED, TaskState.NOT_RETURNED].includes(
          order.deliveryStatus
        )
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <DeliveryProviderBadge orderOrigin={order.orderOrigin} deliveryProvider={order.rider?.thirdPartyProvider} />
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography fontWeight={500} textTransform="capitalize">
                {(riderNameV2 ?? '-').toLowerCase().replace(/\s*\([^)]*\)/g, '')}
              </Typography>
              {isShowEtaV2 && (
                <EtaTimestamp
                  order={order}
                  orderDeliveryStatus={order.deliveryStatus}
                  orderDeliveryStartedAt={order.deliveryStartedAt}
                  orderEstimatedDeliveryAt={order.estimatedDeliveryAt}
                  orderEstimatedArrivalAtStoreAt={order.estimatedArrivalAtStoreAt}
                  isStacked={!!order.stackedWithTaskIds?.length}
                />
              )}
            </Box>
            {isStackedV2 && (
              <Tooltip title="Stacked task">
                <LayersOutlinedIcon
                  color="primary"
                  sx={{
                    padding: '0px',
                    fontSize: '25px',
                    marginBottom: '-5px',
                    marginRight: '3px',
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </Stack>
      )

    case OrderTableColumnId.RIDER_STATUS:
      const isWaiting = !!order.deliveryRiderArrivedForPickupAt
      return (
        <Stack
          px={1}
          py={0.5}
          alignItems="center"
          borderRadius={1}
          bgcolor={isWaiting ? 'success.light' : 'text.secondary'}
          color={isWaiting ? 'common.white' : 'background.paper'}
        >
          <Typography variant="body2" fontWeight={600}>{`Rider ${isWaiting ? 'Waiting' : 'Assigned'}`}</Typography>
        </Stack>
      )

    case OrderTableColumnId.WAITING_TIME:
      const WAITING_WARNING = 1
      const WAITING_DANGER = 2

      return (
        <StatusDuration
          order={order}
          from={order.createdAt}
          to={order.pickingStartedAt}
          warnAfter={WAITING_WARNING * MINUTE_IN_MS}
          dangerAfter={WAITING_DANGER * MINUTE_IN_MS}
        />
      )

    case OrderTableColumnId.PICKING_TIME:
      const PICKING_WARNING = 3
      const PICKING_DANGER = 5

      return (
        <StatusDuration
          order={order}
          from={order.pickingStartedAt}
          to={order.pickingEndedAt}
          warnAfter={PICKING_WARNING * MINUTE_IN_MS}
          dangerAfter={PICKING_DANGER * MINUTE_IN_MS}
        />
      )

    case OrderTableColumnId.PACKING_TIME:
      const PACKING_WARNING = 1
      const PACKING_DANGER = 2

      return (
        <StatusDuration
          order={order}
          from={order.packingStartedAt}
          to={order.packingEndedAt}
          warnAfter={PACKING_WARNING * MINUTE_IN_MS}
          dangerAfter={PACKING_DANGER * MINUTE_IN_MS}
        />
      )

    case OrderTableColumnId.RACK_TIME:
      const RACKTIME_WARNING = 2
      const RACKTIME_DANGER = 3
      const RACKTIME_ETA_WARNING = 1.2 // racktime > 20% eta
      const RACKTIME_ETA_DANGER = 1.3 // racktime > 30% eta

      return (
        <StatusDuration
          dontshow={
            order.shippingMethod === ShippingMethod.PICKUP ||
            ![OrderOrigin.ZAPP, OrderOrigin.UBER, undefined].includes(order.orderOrigin)
          }
          order={order}
          from={order.packingEndedAt}
          to={order.deliveryPickedUpAt ?? order.deliveryStartedAt}
          warnAfter={
            order.estimatedArrivalAtStoreAt && order.packingEndedAt
              ? Math.ceil(RACKTIME_ETA_WARNING * (order.estimatedArrivalAtStoreAt - order.packingEndedAt))
              : RACKTIME_WARNING * MINUTE_IN_MS
          }
          dangerAfter={
            order.estimatedArrivalAtStoreAt && order.packingEndedAt
              ? Math.ceil(RACKTIME_ETA_DANGER * (order.estimatedArrivalAtStoreAt - order.packingEndedAt))
              : RACKTIME_DANGER * MINUTE_IN_MS
          }
        />
      )

    case OrderTableColumnId.RIDING_TIME:
      const RIDING_WARNING = 9
      const RIDING_DANGER = 10

      return (
        <StatusDuration
          dontshow={
            order.shippingMethod === ShippingMethod.PICKUP ||
            ![OrderOrigin.ZAPP, OrderOrigin.UBER, undefined].includes(order.orderOrigin) ||
            [TaskState.NOT_RETURNED, TaskState.RETURNED, TaskState.FAILED].includes(order.deliveryStatus)
          }
          order={order}
          from={order.deliveryStartedAt}
          to={order.deliveredAt}
          warnAfter={RIDING_WARNING * MINUTE_IN_MS}
          dangerAfter={RIDING_DANGER * MINUTE_IN_MS}
        />
      )

    case OrderTableColumnId.DELIVERY_TIME:
      const DELIVERY_WARNING = 0.9 * order.promisedDeliveryTime + 10
      const DELIVERY_DANGER = order.promisedDeliveryTime + 10

      return (
        <StatusDuration
          dontshow={order.shippingMethod === ShippingMethod.PICKUP}
          order={order}
          from={order.createdAt}
          to={order.fulfilledAt}
          warnAfter={DELIVERY_WARNING * MINUTE_IN_MS}
          dangerAfter={DELIVERY_DANGER * MINUTE_IN_MS}
        />
      )

    case OrderTableColumnId.DELIVERY_PROMISE:
      return (
        <>
          {!order.externalId
            ? formatNumberRange(
                order.promisedDeliveryTime / MINUTE_IN_MS,
                order.promisedDeliveryTime / MINUTE_IN_MS + 15,
                '-',
                'min'
              )
            : '-'}
        </>
      )

    case OrderTableColumnId.WAITING_AT_PICKUP:
      return (
        <StatusDuration
          dontshow={order.shippingMethod === ShippingMethod.PICKUP}
          order={order}
          from={order.deliveryRiderArrivedForPickupAt}
          to={order.deliveryPickedUpAt}
        />
      )

    case OrderTableColumnId.HANDOVER_AT_PICKUP:
      const readyForHandoverAt = order.deliveryRiderArrivedForPickupAt && order.packingEndedAt
      const handoverStarts = readyForHandoverAt
        ? Math.max(order.deliveryRiderArrivedForPickupAt ?? 0, order.packingEndedAt ?? 0)
        : 0
      const handoverEnds = readyForHandoverAt ? order.deliveryPickedUpAt : 0
      return (
        <StatusDuration
          dontshow={order.shippingMethod === ShippingMethod.PICKUP}
          order={order}
          from={handoverStarts}
          to={handoverEnds}
        />
      )

    case OrderTableColumnId.ORDERED_ITEM_TYPES:
      const weight = parseInt(order.weight.replace('kg', ''))
      const isHeavy = weight >= 10
      const containsValuableItems = false
      const containsFrozenItems = false
      const containsWater = false

      return (
        <Stack direction="row" spacing={1}>
          {isHeavy && <img src="/icons/weight.svg" alt="heavy order" />}
          {containsValuableItems && <DiamondOutlinedIcon />}
          {containsFrozenItems && <AcUnitOutlinedIcon />}
          {containsWater && <WaterDropOutlinedIcon />}
        </Stack>
      )

    case OrderTableColumnId.DELIVERY_FEATURES: {
      const isStacked = !!order.stackedWithTaskIds?.length
      const isSplitted = false

      return (
        <Stack direction="row" spacing={1}>
          {isStacked && <LayersOutlinedIcon />}
          {isSplitted && <CallSplitOutlinedIcon />}
        </Stack>
      )
    }
    default:
      return '–'
  }
}
